import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("user", [
      "me",
      "subscription",
      "clients",
      "adminToken",
      "accessToken",
      "adminRoles",
    ]),

    ...mapGetters(["globalLoading", "lang"]),

    isAdmin() {
      return this.me?.roles?.includes("admin");
    },

    isChiefTherapist() {
      return this.adminRoles?.includes("chiefTherapist");
    },

    isClient() {
      return this.me?.roles?.includes("client");
    },

    isTherapist() {
      return this.me?.roles?.includes("therapist");
    },

    isMeCompanyUser() {
      return this.me?.company;
    },

    hasNoTherapist() {
      return this.isTherapist || this.myTherapist;
    },

    myTherapist() {
      return this.me?.therapist || null;
    },

    myTherapistId() {
      return this?.myTherapist?._id || null;
    },

    myClients() {
      if (!this.isTherapist) return [];
      return this.clients || [];
    },

    myTherapyType() {
      return this.me?.therapyType;
    },

    myInsurance() {
      return this.me?.insurance;
    },

    myActiveClients() {
      return (
        this.myClients?.filter((c) => {
          return (
            c?.metadata?.numberLiveSessionsHave > 0 ||
            c?.metadata?.numberCorporateLiveSessionsHave > 0 ||
            c?.metadata?.numberFreeLiveSessionsHave > 0 ||
            c?.subscription?.stripeSubscription
          );
        }) || []
      );
    },
  },

  methods: {
    ...mapMutations([
      "awaitPromiseCallback",
      "setGlobalLoading",
      "addNotification",
    ]),
    ...mapActions("user", ["getMe", "getClientsAndSubscriptions"]),

    getUserName(user) {
      if (user?.profile?.anonymous) return user?.profile?.username;

      let firstName =
        this.lang === "ar"
          ? user?.profileAr?.firstName || user?.profile?.firstName
          : user?.profile?.firstName;
      let lastName =
        this.lang === "ar"
          ? user?.profileAr?.lastName || user?.profile?.lastName
          : user?.profile?.lastName;

      return firstName + " " + lastName;
    },

    async getClients() {
      await this.getClientsAndSubscriptions();
    },

    getClientById(id) {
      return this.myClients.find((c) => c._id === id);
    },
  },
};
